@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components {
   
    #crxjs-ext {
      @apply text-black bg-gray-100;
    }
  } */

  /* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.3/flowbite.css" integrity="sha512-rGqRjILjohR2l4/xWr7TvP42nLhZLBa0C5dCuvFXG6gIrbzElZe0+W7ItgNgJCch+3iVUk0WFMxCJR7jXn9Ylg==" crossorigin="anonymous" referrerpolicy="no-referrer" /> */
  @layer components {
    .circlegraph {
       @apply flex-col md:flex-row;
    }
    .chat1{
      @apply md:w-3/4
    }
    .recommendation1{
      @apply flex flex-col md:flex-row md:p-1 p-1
    }
    .recbox{
      @apply md:mx-5 mx-1
    }
  }