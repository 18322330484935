body{
    font-family: Inter;
}
.container{
  width:1100;
  margin: auto;
}
section{
    display: flex;
    align-items: center;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    
}
.guy{
  margin: 10px;
}

.inner-row{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;

}

.scanning{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.scan{
  text-align: center;
  border-radius: 50px;
  padding: 3px 5px;
  color: white;
  display: inline-block;
  background-color: #00AEEF;
  width: fit-content;
  margin: 20px;
}

/* .repScan{
  display: flex;
  justify-content: center;
  align-items: center;
} */


img{ 
    height: 200px;
}

.logo-col{
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
}

.col-logo{
    height: 120px;
}
.hms-text{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
}

.profile{
    height: 200px;
}

.upper{
    background-color: #CCEBFF;
    padding: 0;
    border: 1px solid #00AEEF;

}
/*table styling*/

table {
    font-family: Inter;
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
  }

  .index-table td{
    padding: 10px 40px;
    text-align: left;
  }
  
  th {
    text-transform: uppercase;
    color: #fff;
    background-color: #00AEEF;
    padding: 10px;
    text-align: center;
  }

  
  td {
    color: #000;
    padding: 10px;
    text-align: center;
  }
  .mv-left{
    text-align: left;
    padding-left: 40px;
  }

  tbody{
    background-color: #CCEBFF;
  }
  
  tbody tr:first-child td[colspan] {
    background-color: none;
    font-weight: bold;
  }

  tr:first-child td[colspan]:first-child{
    text-align: left;

  }
  .title-span{
    color: #fff;
    padding: 3px 5px;
    width: fit-content;
    background-color: #00AEEF;
  }
  
  tr:first-child td[colspan]:first-child span{
    color: #fff;
    text-align: left;
  }
  
  tbody tr:not(:first-child) {
    border-top: none;
  }
  



.signs-table {
    padding-bottom: 10px 40px;
}
.mLeft{
  margin-left: 30px;
  width: 100px;
}
.mSize{
  width: 100px;
  text-align: center;
}
.box-meter-img{
    height: 20px;
    margin-left: 90px;
}
.tlight{
  background-color: #def1ff;
}
.meter{
  height: 30px;
}
.scoreHead{
  margin: 5px;
  font-size: large;
  font-weight: 600;
}
.word__li{
  margin: 2px;
  font-size: medium;
  font-weight: 400;
}
.report-btn {
  position: relative;
  top: 12%;
  left: 12%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  border: 1px solid #00AEEF;
  padding: 5px 10px;
  font-size: 23px;
  border-radius: 5px;
}

.dummy-btn{
  display: flex;
  justify-content: center;
  
}


.hidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 3s ease-out, opacity 0.3s ease-out;
}

.hidden-description {
  display: none;
}

.visible .hidden-description {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  transition: max-height 0.3s ease-out; /* add transition to the max-height property */
}
.col-4{
  display: grid;
}

/* Define grid for description */
.row {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr; /* first column takes up 1/3, second takes up 2/3 */
  padding: 30px; /* adds some spacing between columns */
  

}

/* Style the image to be a maximum of 100% of its parent container's width */
.row img {
  max-width: 100%;
  justify-self: center;
}
